import React, { useEffect, useState } from "react"
import styled from "styled-components"

const Order = ({ cart, total }) => {
  const [items, setItems] = useState("")

  useEffect(() => {
    let items = ""
    cart.map(item => {
      let itemStr = `${item.name} - ${item.price}KM;\n`

      items += itemStr

      setItems(items)
    })
  })

  return (
    <Section>
      <h1>Završi narudžbu:</h1>
      <form
        action="https://formsubmit.co/velikeslikenaplatnu@gmail.com"
        method="POST"
      >
        <label htmlFor="ime">Ime i prezime: </label>
        <input
          name="ime"
          id="ime"
          type="text"
          placeholder="Ime i prezime"
          required
        />
        <label htmlFor="email">Email adresa:</label>
        <input
          name="email"
          id="email"
          type="email"
          placeholder="Email adresa"
          required
        />
        <label htmlFor="adresa">Adresa: </label>
        <input
          name="adresa"
          id="adresa"
          type="text"
          placeholder="Adresa"
          required
        />
        <label htmlFor="grad">Grad: </label>
        <input name="grad" id="grad" type="text" placeholder="Grad" required/>
        <label htmlFor="telefon">Broj telefona: </label>
        <input
          name="telefon"
          id="telefon"
          type="text"
          placeholder="Broj telefona"
          required
        />
        <textarea className="auto" name="narudžba" value={items} readOnly />
        <input className="auto" name="ukupno" value={`${total}KM`} readOnly />
        <input
          type="hidden"
          name="_autoresponse"
          value="velikeslike.com - Narudžba izvršena!"
        />
        <button type="submit">Naruči</button>
      </form>
    </Section>
  )
}

const Section = styled.section`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 20px auto;
  max-width: 960px;
  width: 100%;
  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 700px;
    width: 90%;

    label {
      display: block;
      margin-bottom: 10px;
    }
    input,
    textarea {
      border: 1px solid #ddd;
      display: block;
      font-family: "Mulish", sans-serif;
      margin-bottom: 20px;
      width: 100%;
      padding: 20px;
    }
    .auto {
      display: none;
    }
    button {
      background-color: #1d1d1d;
      border: 1px solid #ddd;
      color: #eee;
      cursor: pointer;
      padding: 20px;
      width: 100%;
      transition: all 0.3s;
      :hover {
        transform: translateY(-5px);
      }
    }
  }
`

export default Order
