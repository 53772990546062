import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import CartIsEmpty from "../../images/svg/cartIsEmpty.svg"

const EmptyCart = () => {
  return (
    <Cart>
      <CartIsEmpty />
      <h1>Korpa je prazna</h1>
      <Link to="/">Nazad na početnu ></Link>
    </Cart>
  )
}

export default EmptyCart

const Cart = styled.div`
  text-align: center;
  svg {
    margin-top: 40px;
    max-height: 470px;
    max-width: 600px;
    width: 100%;
  }
  h1 {
    letter-spacing: 0.2rem;
    font-size: 24px;
  }
  a {
    color: #524448;
    letter-spacing: 0.2rem;
    padding: 10px 20px;
    transition: all 0.3s;
  }
`
